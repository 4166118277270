import 'src/components/MainForm/MainForm.scss'

import { ErrorMessage, Field, Form, Formik } from 'formik'
import { FC, useRef, useState } from 'react'
import ReCAPTCHA from "react-google-recaptcha"
import { Navigate } from 'react-router'
import { removeHTMLTags } from 'src/utils'
import * as Yup from 'yup'
import { ReactComponent as ArrowIcon } from '../../assets/icons/button-arrow.svg'
import countries from '../../data/countries.json'
import { businesses, BusinessesTypes, FormValues, referrals, roles, SelectField } from '../../sections/capterra/ContactForm'
import { useSurveyApi } from '../Email'
import { patternGenerator } from '../Patterns/patternsGenerator'
import { accessControl, amenitiesStrucure, bookingSystem, memberData, onboardingProcess, paymentsStructure, pointofSale, propertyManagement, propertyType, referredFields, reservationSystem } from './constants'

const patterns = patternGenerator({ quantity: 6, theme: 'dark' })

type ReferredFieldName = 'referral_client' | 'referred_by'

interface ReferredField {
  name: ReferredFieldName;
  title: string;
  placeholder: string;
}
export type amenities = {
  id?: number
  title?: string
  image?: string
}
export type payments = {
  id?: number
  title?: string
  image?: string
}
export type integrations = {
  id?: number
  title?: string

}

export const MainForm: FC = () => {
  const reCaptchaElement = useRef<ReCAPTCHA>(null)
  const [business, setBusiness] = useState('Private Members Clubs')
  const [selectedAmenities, setSelectedAmenities] = useState<string[]>([]);
  const [selectedPayments, setSelectedPayments] = useState<string[]>([]);
  const [selectedAccessControl, setSelectedAccessControl] = useState<string[]>([]);
  const [showReferredField, setShowReferredField] = useState<ReferredField | undefined>(undefined)

  const { sendEmail, success } = useSurveyApi()
  const initialValues = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    company: '',
    website: '',
    city: '',
    role: '',
    state: '',
    enroll: '',
    country: '',
    timeline: '',
    referral: '',
    referral_client: '',
    new_property: '',
    system_used: '',
    membership_type: '',
    member_count: '',
    otherAmenity: '',
    point_of_sale: '',
    property_management_system: '',
    reservation_system: '',
    booking_system: '',
    member_approval: '',
    referred_by: '',
  };
  

  const handleChangeCheckbox = (value: string, stateFunction: React.Dispatch<React.SetStateAction<string[]>>) => {
    stateFunction( prevState => {
      if (prevState.includes(value)) {
        return prevState.filter(string => string !== value);
      } else {
        // Add the payment to the selection
        return [...prevState, value]
      }
    })
  }

  function handleCard(id: string): void {
    setBusiness(id)
  }


  const errorMessage = 'This field is required'
  
  const onSubmit = (formValues: FormValues): void => {
    const amenities = removeHTMLTags(selectedAmenities.join(','))
    const payments = removeHTMLTags(selectedPayments.join(','))
    const accessControl = removeHTMLTags(selectedAccessControl.join(','))
    sendEmail(1878, {
      15855: `${business}`,
      2770: `${formValues?.firstName}`,
      2771: `${formValues?.lastName}`,
      2838: `${formValues?.phone}`,
      2773: `${formValues?.email}`,
      1489: `${formValues?.company}`,
      15856: `${formValues?.website}`,
      18977: `${formValues.city}`,
      16164: `${formValues.role}`,
      83569: `${formValues.state}`,
      83570: `${formValues.enroll}`,
      18615: `${formValues.country}`,
      18978: `${formValues.timeline}`,
      18979: `${formValues.referral}`,
      53619: `${formValues.referral_client}`,
    //83552: `${formValues.new_property}`,
    //83553: `${formValues.system_used}`,
      83554: `${formValues.membership_type}`,
      83555: `${formValues.member_count}`,
      83556: `${amenities}`,
      83571: `${formValues.otherAmenity}`,
      83557: `${payments}`,
      83558: `${formValues.point_of_sale}`,
      83559: `${formValues.property_management_system}`,
      83560: `${formValues.reservation_system}`,
      83742: `${formValues.booking_system}`,
      83561: `${accessControl}`,
      83562: `${formValues.member_approval}`,
      54961: `${formValues.referred_by}`,
      // 64049: `${formValues.leverageMembershipProgram}`,
      18616: '',
    })
  }

  const validationSchema = Yup.object({
    firstName: Yup.string().required(errorMessage),
    lastName: Yup.string().required(errorMessage),
    phone: Yup.string().required(errorMessage),
    email: Yup.string().email('Invalid email format').required(errorMessage),
    company: Yup.string().required(errorMessage),
    website: Yup.string().required(errorMessage),
    city: Yup.string().required(errorMessage),
    role: Yup.string().required(errorMessage),
    state: Yup.string().required(errorMessage),
    enroll: Yup.string().required(errorMessage),
    country: Yup.string().required(errorMessage),
    timeline: Yup.string().required(errorMessage),
    referral: Yup.string().required(errorMessage),
    referral_client: showReferredField?.name == 'referral_client' ? Yup.string().required(errorMessage) : Yup.string(),
   // new_property: Yup.string().required(errorMessage),
   // system_used: Yup.string().required(errorMessage),
    membership_type: Yup.string().required(errorMessage),
    member_count: Yup.string().required(errorMessage),
    booking_system: Yup.string().required(errorMessage),
    point_of_sale: Yup.string().required(errorMessage),
    property_management_system: Yup.string().required(errorMessage),
    reservation_system: Yup.string().required(errorMessage),
    member_approval: Yup.string().required(errorMessage),
    referred_by: showReferredField?.name == 'referred_by' ? Yup.string().required(errorMessage) : Yup.string(),
    // leverageMembershipProgram: Yup.string().required(errorMessage),
    // selectedAmenities: Yup.array().of(Yup.string()).required('Select at least one amenity'),
    // otherAmenity: Yup.string().when('selectedAmenities', {
    //   is: (amenities: string[]) => amenities.includes('Other'),
    //   then: Yup.string().required('Please specify the other amenity'),
    // }),
  })

  const onChangeReferralClient = (value: string) => {
    if (value === "Client Referral") {
      setShowReferredField(referredFields.find(item => item.name === 'referral_client'))
      return;
    }
    if (value === "Word of Mouth") {
      setShowReferredField(referredFields.find(item => item.name === 'referred_by'))
      return;
    }

    setShowReferredField(undefined)
  }

  // const onChangeReCaptcha = () => {
  //   if (reCaptchaElement?.current?.getValue()) {
  //     setIsDisableButton(false)
  //   }
  // }

  if (success) return <Navigate to={{ pathname: `/thank-you` }} />
  if (success === false) return <Navigate to={{ pathname: '/error' }} />
  return (
    <div className='main-contact' id='capterra-contact' role={'presentation'}>
      <div className='ornament-block'>{patterns}</div>
      <div className='container'>
        <div className='main-contact__wrapper'>
          <h2 className='main-contact__title'>LET US SHOW YOU HOW PEOPLEVINE CAN IMPACT YOUR BUSINESS</h2>
          <div className='main-form-block'>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
              {form => (
                <Form className='main-login-form'>
                  <p className='card-list-heading'>Which Vertical represents your business?</p>
                  <ul className='card-list'>
                    {businesses.map((item: BusinessesTypes) => (
                      <li
                        key={item.id}
                        className={`small-card small-card-${business === item.title ? 'selected' : ''}`}
                        onClick={() => handleCard(item.title)}
                      >
                        <div className='small-card-radio' />
                        <div className='small-card-image'>
                          <img src={item.image} alt='logo' />
                        </div>
                        <div className='small-card-text'>{item.title}</div>
                      </li>
                    ))}
                  </ul>
                  <div className='login-form__wrapper'>
                    <div className='login-form__row'>
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='firstName'>
                            First name
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='text'
                            className={`login-form__inputfield 
                        ${form?.errors.firstName && form?.touched.firstName && 'login-form__inputfield--error'}`}
                            name='firstName'
                            placeholder='Enter First name'
                            autoComplete="off"
                          />
                        </div>
                        <ErrorMessage name='firstName' component='span' className='login-form__error-message' />
                      </div>
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='lastName'>
                            Last name
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='text'
                            className={`login-form__inputfield 
                        ${form?.errors.lastName && form?.touched.lastName && 'login-form__inputfield--error'}`}
                            name='lastName'
                            placeholder='Enter Last name'
                            autoComplete="off"
                          />
                        </div>
                        <ErrorMessage name='lastName' component='span' className='login-form__error-message' />
                      </div>
                    </div>
                    <div className='login-form__row'>
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='phone'>
                            Phone
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='number'
                            className={`login-form__inputfield 
                        ${form?.errors.phone && form?.touched.phone && 'login-form__inputfield--error'}`}
                            name='phone'
                            placeholder='Enter your phone'
                            autoComplete="off"
                          />
                        </div>
                        <ErrorMessage name='phone' component='span' className='login-form__error-message' />
                      </div>
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='email'>
                            Business email address
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='text'
                            className={`login-form__inputfield 
                        ${form?.errors.email && form?.touched.email && 'login-form__inputfield--error'}`}
                            name='email'
                            placeholder='Enter your Email'
                            autoComplete="off"
                          />
                        </div>
                        <ErrorMessage name='email' component='span' className='login-form__error-message' />
                      </div>
                    </div>
                    <div className='login-form__row'>
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='company'>
                            Company Name
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='text'
                            className={`login-form__inputfield 
                        ${form?.errors.company && form?.touched.company && 'login-form__inputfield--error'}`}
                            name='company'
                            placeholder='Enter Company Name'
                            autoComplete="off"
                          />
                        </div>
                        <ErrorMessage name='company' component='span' className='login-form__error-message' />
                      </div>
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='website'>
                            Company Website
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='text'
                            className={`login-form__inputfield 
                        ${form?.errors.website && form?.touched.website && 'login-form__inputfield--error'}`}
                            name='website'
                            placeholder='Enter Company Website'
                            autoComplete="off"
                          />
                        </div>

                        <ErrorMessage name='website' component='span' className='login-form__error-message' />
                      </div>

                    </div>
                    <div className='login-form__row'>
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='city'>
                            City
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='text'
                            className={`login-form__inputfield 
                        ${form?.errors.city && form?.touched.city && 'login-form__inputfield--error'}`}
                            name='city'
                            placeholder='City'
                            autoComplete="off"
                          />
                        </div>
                        <ErrorMessage name='city' component='span' className='login-form__error-message' />
                      </div>
                      <div className='login-form__input' style={{ marginBottom: 0 }}>
                        <Field name={'role'} placeholder='What is your role?' component={SelectField} required={true} options={roles} />
                      </div>
                    </div>
                    <div className='login-form__row'>
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='state'>
                            State
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='text'
                            className={`login-form__inputfield 
                        ${form?.errors.state && form?.touched.state && 'login-form__inputfield--error'}`}
                            name='state'
                            placeholder='Enter State'
                            autoComplete="off"
                          />
                        </div>
                        <ErrorMessage name='state' component='span' className='login-form__error-message' />
                      </div>
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='enroll'>
                          Application launch date?
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='text'
                            className={`login-form__inputfield 
                        ${form?.errors.enroll && form?.touched.enroll && 'login-form__inputfield--error'}`}
                            name='enroll'
                            placeholder='DD/MM/YY'
                            autoComplete="off"
                          />
                        </div>

                        <ErrorMessage name='enroll' component='span' className='login-form__error-message' />
                      </div>
                    </div>

                    <div className='login-form__row'>
                      <div className='login-form__input' style={{ marginBottom: 0 }}>
                        <Field
                          name={'country'}
                          placeholder='Country'
                          component={SelectField}
                          required={true}
                          options={countries.map(it => ({ label: it.name, value: it.name }))}
                        />
                      </div>
                      {/* WHEN DOES THE CLUB OPEN */}
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='timeline'>
                            When does the club officially open?
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='text'
                            className={`login-form__inputfield 
                        ${form?.errors.timeline && form?.touched.timeline && 'login-form__inputfield--error'}`}
                            name='timeline'
                            placeholder='DD/MM/YY'
                          />
                        </div>

                        <ErrorMessage name='website' component='span' className='login-form__error-message' />
                      </div>
                    </div>

                    {/* HOW DID YOU FIND US */}
                    <div className='login-form__input form__input-client-referral' style={{ marginBottom: 0 }}>
                      <Field
                        name={'referral'}
                        placeholder='How did you find us?'
                        component={SelectField}
                        required={true}
                        options={referrals}
                        onChangeCallback={onChangeReferralClient}
                      />

                      {showReferredField && (
                        <div className='login-form__input'>
                          <div className='login-form__input-inner-box'>
                            <label className='login-form__label' htmlFor='company'>
                              {showReferredField.title}
                              <span className='login-form__label-required'> *</span>
                            </label>
                            <Field
                              type='text'
                              className={`login-form__inputfield 
                          ${form?.errors[showReferredField.name] && form?.touched[showReferredField.name] && 'login-form__inputfield--error'}`}
                              name={showReferredField.name}
                              placeholder={showReferredField.placeholder}
                              autoComplete="off"
                            />
                          </div>
                          <ErrorMessage name='firstName' component='span' className='login-form__error-message' />
                        </div>
                      )}
                    </div>


                    {/* What are all of your membership types and their respective rates?*/}
                    <div className='login-form__input-inner-box'>
                      <div className=''>
                        <div className='login-form__input'>
                          <div className='login-form__input-inner-box'>
                            <label className='login-form__label' htmlFor='membership_type'>
                            Membership types and rates?
                              <span className='login-form__label-required'> *</span>
                            </label>
                            <Field
                              type='text'
                              className={`login-form__inputfield 
                        ${form?.errors.membership_type && form?.touched.membership_type && 'login-form__inputfield--error'}`}
                              name='membership_type'
                              placeholder='membership type and rate'
                              autoComplete="off"
                            />
                          </div>
                          <ErrorMessage name='membership_type' component='span' className='login-form__error-message' />
                        </div>
                      </div>
                    </div>

                    {/* memberCOUNT */}
                    <div className='login-form__input-inner-box'>
                      <div className=''>
                        <div className='login-form__input'>
                          <div className='login-form__input-inner-box'>

                            <Field
                              name={'member_count'}
                              placeholder='Number of active members?'
                              component={SelectField}
                              required={true}
                              options={memberData.data.map(it => ({ label: it.name, value: it.name }))}
                            />
                          </div>
                          <ErrorMessage name='member_count' component='span' className='login-form__error-message' />
                        </div>
                      </div>
                    </div>

                    {/* AMENITIES */}
                    <p className='card-list-heading'>Which services and amenities would you like to have your guests and members reserve?</p>
                    <ul className='card-list cardlist-v2'>
                      {amenitiesStrucure.map((item: amenities) => (
                        <li
                          key={item.id}
                          className={`small-card small-card-${selectedAmenities.includes(item.title!) ? 'selected' : ''}`}
                          onClick={() => handleChangeCheckbox(item.title!, setSelectedAmenities)}
                        >
                          <div className='small-card-radio' />
                          <div className='small-card-image'>
                            <img src={item.image} alt='logo' />
                          </div>
                          <div className='small-card-text'>{item.title}</div>
                        </li>
                      ))}
                    </ul>
                    {selectedAmenities.includes("Other") && (
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='otherAmenity'>
                            Please specify
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='text'
                            className={`login-form__inputfield 
                        ${form?.errors.otherAmenity && form?.touched.otherAmenity && 'login-form__inputfield--error'}`}
                            name='otherAmenity'
                            placeholder='Enter additional amenities'
                          />
                        </div>
                        <ErrorMessage name='otherAmenity' component='span' className='login-form__error-message' />
                      </div>
                    )}

                    {/* PAYMENTS ACCEPT?? */}
                    <p className='card-list-heading spacer-v2'>How will you be accepting payments?</p>
                    <ul className='card-list cardlist-v2'>
                      {paymentsStructure.map((item) => (
                        <li
                          key={item.id}
                          className={`small-card small-card-${selectedPayments.includes(item.title) ? 'selected' : ''}`}
                          onClick={() => handleChangeCheckbox(item.title!, setSelectedPayments)}
                        >
                          <div className='small-card-radio' />
                          <div className='small-card-image'>
                            <img src={item.image} alt='logo' />
                          </div>
                          <div className='small-card-text' dangerouslySetInnerHTML={{ __html: item.title }}></div>
                        </li>
                      ))}
                    </ul>

                    {/* INTEGRATIONS ???! */}
                    <p className='card-list-heading spacer-v2'>What is your technology stack?</p>
                    <div className='login-form__row'>
                      <div className='login-form__input' style={{ marginBottom: 0 }}>
                        <Field name={'point_of_sale'} placeholder='Point of Sale (POS)' component={SelectField} required={true} options={pointofSale} />
                      </div>
                      <div className='login-form__input' style={{ marginBottom: 0 }}>
                        <Field name={'property_management_system'} placeholder='Property Management System (PMS)' component={SelectField} required={true} options={propertyManagement} />
                      </div>
                    </div>
                    <div className='login-form__row'>
                      <div className='login-form__input' style={{ marginBottom: 0 }}>
                        <Field name={'reservation_system'} placeholder='Reservation System' component={SelectField} required={true} options={reservationSystem} />
                      </div>
                      <div className='login-form__input' style={{ marginBottom: 0 }}>
                        <Field name={'booking_system'} placeholder='Spa/Activities System' component={SelectField} required={true} options={bookingSystem} />
                      </div>
                    </div>

                    {/* ACCESS CONTROL */}
                    <p className='card-list-heading spacer-v2'>Access Control System</p>
                    <ul className='card-list cardlist-v2'>
                      {accessControl.map((item) => (
                        <li
                          key={item.id}
                          className={`small-card small-card-${selectedAccessControl.includes(item.title) ? 'selected' : ''}`}
                          onClick={() => handleChangeCheckbox(item.title!, setSelectedAccessControl)}
                        >
                          <div className='small-card-radio' />

                          <div className='small-card-text' dangerouslySetInnerHTML={{ __html: item.title }}></div>
                        </li>
                      ))}
                    </ul>
                    {/* LAST QUESTION */}
                    <div className='login-form__input form__input-client-referral' style={{ marginBottom: 0 }}>
                      <Field
                        name={'member_approval'}
                        placeholder='What is you member onboarding process?'
                        component={SelectField}
                        required={true}
                        options={onboardingProcess}
                        onChangeCallback={onChangeReferralClient}
                      />
                    </div>
                    {/* <div className='login-form__row-full'>
                      <div className='login-form__input'>
                        <div className='login-form__input-inner-box'>
                          <label className='login-form__label' htmlFor='company'>
                            How are you looking to leverage Peoplevine for your membership program?
                            <span className='login-form__label-required'> *</span>
                          </label>
                          <Field
                            type='text'
                            className={`login-form__inputfield login-form__textarea 
                        ${form?.errors.leverageMembershipProgram && form?.touched.leverageMembershipProgram && 'login-form__inputfield--error'}`}
                            name='leverageMembershipProgram'
                            placeholder='Enter your answer'
                          />
                        </div>
                        <ErrorMessage name='leverageMembershipProgram' component='span' className='login-form__error-message' />
                      </div>
                    </div> */}
                  </div>

                  {/* <ReCAPTCHA
                    ref={reCaptchaElement}
                    sitekey="6Le-zJgoAAAAAGHvte0r_NEFyeYfU1h8pkoEBoQh"
                    onChange={onChangeReCaptcha}
                  /> */}
                  <div className='login-form__button'>
                    <button type='submit' disabled={form?.isSubmitting}>
                      Get free demo
                      <ArrowIcon />
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}
